import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';
import {
  JSONParseSafe,
} from 'utils';
import getConfig from 'config';
import { prepareHeaders } from './oAuth2';
import { BFF_API } from './urls';

const customFetch = (input: RequestInfo | URL, init?: RequestInit) => fetch(
  `${input}${typeof init?.body === 'string' ? `?op=${JSONParseSafe(init.body)?.operationName || null}` : ''}`,
  init,
);

export const graphqlClient = new GraphQLClient(
  BFF_API,
  {
    fetch: customFetch,
    errorPolicy: 'all',
    headers: {
      'x-sdk-version': getConfig().NEXT_PUBLIC_SDK_VERSION,
    },
  },
);

export const getBaseQuery = () => graphqlRequestBaseQuery({
  client: graphqlClient as any,
  prepareHeaders,
});

export const api = createApi({
  baseQuery: getBaseQuery(),
  endpoints: () => ({}),
});
