import { CryptoAlgorithm, Encoding } from '@super-protocol/dto-js/build/enum';
import {
  Cipher, EncryptionWithIV, HashAlgorithm, Hash, EncryptionWithMacIV, Encryption,
} from '@super-protocol/dto-js';
import { Crypto } from '@super-protocol/sdk-js';
import { CryptoUtils } from 'utils/crypto/CryptoUtils';
import { CryptoBrowserify } from 'utils/crypto/CryptoBrowserify';

export interface EncryptFileStreamResult {
    encryptedStream: ReadableStream;
    encryption: EncryptionWithIV;
    getAuthTag: () => Buffer;
    getHash: () => Hash;
}

export interface EncryptFileStreamProps {
  stream: ReadableStream;
}

export interface DecryptStreamResult {
  decryptedStream: ReadableStream;
}

export interface DecryptStreamProps {
  encryption: Omit<EncryptionWithMacIV, 'algo' | 'encoding' | 'cipher'>;
  stream: ReadableStream;
}

export class CryptoFile {
  public static async decrypt(encryption: Encryption) {
    return Crypto.decrypt(encryption);
  }
  public static async encrypt(content: string, encryption: Encryption) {
    return Crypto.encrypt(content, encryption);
  }
  public static async encryptStream(props: EncryptFileStreamProps): Promise<EncryptFileStreamResult> {
    const { stream } = props;
    const { privateKeyBase64 } = await CryptoUtils.generateRandomKeys();
    const {
      stream: hashedStream,
      getHash,
    } = await CryptoBrowserify.createHashFromStream(
      stream,
      HashAlgorithm.SHA256,
      Encoding.hex,
    );
    const { stream: encryptedStream, encryption, getAuthTag } = await CryptoBrowserify.encryptStream(
      hashedStream,
      {
        key: privateKeyBase64,
        algo: CryptoAlgorithm.AES,
        encoding: Encoding.base64,
        cipher: Cipher.AES_256_GCM,
      },
    );
    return {
      encryptedStream,
      encryption,
      getAuthTag,
      getHash,
    };
  }

  public static async decryptStream(props: DecryptStreamProps): Promise<DecryptStreamResult> {
    const { stream, encryption } = props;
    const { stream: decryptedStream } = await CryptoBrowserify.decryptStream(
      stream,
      {
        ...encryption,
        algo: CryptoAlgorithm.AES,
        encoding: Encoding.base64,
        cipher: Cipher.AES_256_GCM,
      },
    );
    return {
      decryptedStream,
    };
  }
}