import {
  createCipheriv,
  randomBytes,
  CipherCCM,
  Cipher,
  CipherGCMOptions,
  createHash,
  Decipher,
  DecipherGCM,
  createDecipheriv,
} from 'crypto';
import {
  Encryption, EncryptionWithIV, Encoding, HashAlgorithm, Hash, EncryptionWithMacIV,
} from '@super-protocol/dto-js';

export interface EncryptStreamResult {
  stream: ReadableStream<Uint8Array>;
  encryption: EncryptionWithIV;
  getAuthTag: () => Buffer;
}

export interface DecryptStreamResult {
  stream: ReadableStream<Uint8Array>;
}

export interface CreateHashFromStreamResult {
  stream: ReadableStream<Uint8Array>;
  getHash: () => Hash;
}

export class CryptoBrowserify {
  public static readonly isGCM = (cipher: string): boolean => /gcm/i.test(cipher);
  public static getIVLength(cipher: string): number {
    if (this.isGCM(cipher)) {
      return 12;
    }
    return 16;
  }

  public static createCipher(cipher: string, key: Buffer, iv: Buffer): CipherCCM | Cipher {
    if (this.isGCM(cipher)) {
      const options: CipherGCMOptions = {
        authTagLength: 16,
      };
      return createCipheriv(cipher, key, iv, options);
    }
    return createCipheriv(cipher, key, iv);
  }

  public static createDecipher(cipher: string, key: Buffer, iv: Buffer, mac?: Buffer): Decipher {
    const options: CipherGCMOptions = {};
    if (this.isGCM(cipher)) {
      options.authTagLength = 16;
    }
    const decipher: DecipherGCM = createDecipheriv(cipher, key, iv, options) as DecipherGCM;
    if (mac) {
      decipher.setAuthTag(mac);
    }
    return decipher;
  }

  public static createIV(cipher: string): Buffer {
    const length: number = this.getIVLength(cipher);
    return randomBytes(length);
  }

  public static async encryptStream(
    readableStrem: ReadableStream<Uint8Array>,
    encryptionProp: Encryption,
  ): Promise<EncryptStreamResult> {
    const {
      key, algo, encoding = Encoding.base64, cipher,
    } = encryptionProp;
    if (!key) throw new Error('key required');
    if (!cipher) throw new Error('cipher required');
    if (!algo) throw new Error('algo required');
    const iv = this.createIV(cipher);
    const cipherIv = this.createCipher(cipher, Buffer.from(key, encoding), iv);

    const encryptStream = new TransformStream<Uint8Array, Uint8Array>({
      async transform(chunk, controller) {
        controller.enqueue(new Uint8Array(cipherIv.update(chunk)));
      },
      async flush(controller) {
        controller.enqueue(new Uint8Array(cipherIv.final()));
      },
    });

    const encryption: EncryptionWithIV = {
      algo,
      iv: Buffer.from(iv).toString(encoding),
      encoding,
      key,
      cipher,
    };

    const getAuthTag = () => (cipherIv as CipherCCM)?.getAuthTag?.(); // required for aes-gcm

    return { stream: readableStrem.pipeThrough(encryptStream), encryption, getAuthTag };
  }

  public static async createHashFromStream(
    inputStream: ReadableStream<Uint8Array>,
    algorithm: HashAlgorithm,
    encoding = Encoding.base64,
  ): Promise<CreateHashFromStreamResult> {
    const hash = createHash(algorithm);

    const transformStream = new TransformStream<Uint8Array, Uint8Array>({
      async transform(chunk, controller) {
        controller.enqueue(chunk);
        hash.update(chunk);
      },
    });

    const getHash = () => {
      return {
        algo: algorithm,
        encoding,
        hash: hash.digest(encoding),
      };
    };

    return { stream: inputStream.pipeThrough(transformStream), getHash };
  }

  public static async decryptStream(
    readableStrem: ReadableStream<Uint8Array>,
    encryptionProp: EncryptionWithMacIV,
  ): Promise<DecryptStreamResult> {
    const {
      key, algo, encoding = Encoding.base64, cipher, iv, mac,
    } = encryptionProp;
    if (!key) throw new Error('key required');
    if (!cipher) throw new Error('cipher required');
    if (!algo) throw new Error('algo required');

    const decipher: Decipher = this.createDecipher(
      cipher,
      Buffer.from(key, encoding),
      Buffer.from(iv, encoding),
      Buffer.from(mac, encoding),
    );

    const decryptStream = new TransformStream<Uint8Array, Uint8Array>({
      async transform(chunk: Uint8Array, controller: TransformStreamDefaultController) {
        controller.enqueue(new Uint8Array(decipher.update(chunk)));
      },
      async flush(controller: TransformStreamDefaultController) {
        controller.enqueue(new Uint8Array(decipher.final()));
      },
    });

    return { stream: readableStrem.pipeThrough(decryptStream) };
  }
}