import { OfferFilter, ConnectionArgs, TOfferType } from 'generated/types';

export interface Variables { filter: OfferFilter, pagination: ConnectionArgs }

export enum ControlPanelFields {
  sorting = 'sorting',
  size = 'size',
  view = 'view',
}

export enum ViewType {
  table = 'table',
  cards = 'cards'
}

export enum Sorting {
  newest = 'newest',
  price = 'price',
}

export enum Pages {
  models = 'models',
  datasets = 'datasets',
}

export enum FiltersFields {
  offerType = 'offerType',
  compatible = 'compatible',
  offers = 'offers',
}

export enum Compatible {
  all = 'all',
  compatible = 'compatible'
}

export enum Offers {
  sp = 'sp',
  approved = 'approved',
  unmoderated = 'unmoderated',
  inactive = 'inactive'
}

export enum Blocks {
  filters = 'filters',
  controlPanel = 'controlPanel'
}

export interface FormControlPanel {
  [ControlPanelFields.sorting]?: Sorting;
  [ControlPanelFields.size]?: number | null;
  [ControlPanelFields.view]?: ViewType | null;
}

export interface FormFiltersDatasets {
  [FiltersFields.offerType]?: TOfferType;
  [FiltersFields.compatible]?: Compatible;
  [FiltersFields.offers]?: Offers;
}

export interface FormFiltersModels {
  [FiltersFields.offerType]?: TOfferType;
  [FiltersFields.compatible]?: Compatible;
  [FiltersFields.offers]?: Offers;
}

export interface FormModels {
  [Blocks.filters]?: FormFiltersModels | null;
  [Blocks.controlPanel]?: FormControlPanel | null;
}

export interface FormDatasets {
  [Blocks.filters]?: FormFiltersDatasets | null;
  [Blocks.controlPanel]?: FormControlPanel | null;
}

export interface FiltersState {
  [Pages.models]: FormModels;
  [Pages.datasets]: FormDatasets;
}