import { BuildOrderForm } from 'lib/features/createOrderV2/types';
import { getOffersAndTeeOffersIds } from 'lib/features/createOrderV2/helpers';
import {
  OfferFilter, TOfferType, ConnectionArgs, SortDir,
} from 'generated/types';
import {
  FormControlPanel,
  FiltersFields,
  Blocks,
  Compatible,
  FiltersState,
  Offers,
  Pages,
  FormModels,
  FormFiltersModels,
  ControlPanelFields,
  Sorting,
  FormFiltersDatasets,
  FormDatasets,
  Variables,
  ViewType,
} from './types';

export const getPreparedSortingModels = (controlPanel?: FormControlPanel | null): ConnectionArgs => {
  switch (controlPanel?.[ControlPanelFields.sorting]) {
    case Sorting.newest:
      return { sort: [{ sortBy: 'origins.modifiedDate', sortDir: SortDir.Desc }] };
    default:
      return { sort: [{ sortBy: 'price', sortDir: SortDir.Asc }] };
  }
};

export const getPreparedSortingDatasets = (controlPanel?: FormControlPanel | null): ConnectionArgs => {
  switch (controlPanel?.[ControlPanelFields.sorting]) {
    case Sorting.newest:
      return { sort: [{ sortBy: 'origins.modifiedDate', sortDir: SortDir.Desc }] };
    default:
      return { sort: [{ sortBy: 'price', sortDir: SortDir.Asc }] };
  }
};

export const getPreparedPaginationDatasets = (formDatasets: FormDatasets): ConnectionArgs => ({
  ...getPreparedSortingDatasets(formDatasets.controlPanel),
  first: 10,
});

export const getPreparedPaginationModels = (formModels: FormModels): ConnectionArgs => ({
  ...getPreparedSortingModels(formModels.controlPanel),
  first: 10,
});

export const getPreparedFiltersDatasets = (
  formModels: FormModels,
  buildOrderForm?: BuildOrderForm,
): OfferFilter => {
  const { filters } = (formModels || {});
  const { compatible } = (filters || {});
  const selectedOfferIds = getOffersAndTeeOffersIds({ datasets: buildOrderForm?.datasets })?.offers;
  return {
    offerType: TOfferType.Data,
    ...(compatible !== Compatible.all && selectedOfferIds?.length ? { selectedOfferIds } : {}),
    inactive: false,
    enabled: true,
  };
};

export const getPreparedFiltersModels = (
  formModels: FormModels,
  buildOrderForm?: BuildOrderForm,
): OfferFilter => {
  const { filters } = (formModels || {});
  const { compatible } = (filters || {});
  const selectedOfferIds = getOffersAndTeeOffersIds({ model: buildOrderForm?.model })?.offers;
  return {
    offerType: TOfferType.Data,
    ...(compatible !== Compatible.all && selectedOfferIds?.length ? { selectedOfferIds } : {}),
    inactive: false,
    enabled: true,
  };
};

export const getPreparedFormModels = (formModels: FormModels, buildOrderForm?: BuildOrderForm): Variables => ({
  filter: getPreparedFiltersModels(formModels, buildOrderForm),
  pagination: getPreparedPaginationModels(formModels),
});

export const getPreparedFormDatasets = (formModels: FormModels, buildOrderForm?: BuildOrderForm): Variables => ({
  filter: getPreparedFiltersDatasets(formModels, buildOrderForm),
  pagination: getPreparedPaginationDatasets(formModels),
});

export const getPreparedForm = (page: Pages, formModels: FormModels, buildOrderForm?: BuildOrderForm): Variables => {
  const forms = {
    [Pages.datasets]: getPreparedFormDatasets,
    [Pages.models]: getPreparedFormModels,
  };
  return forms[page]?.(formModels, buildOrderForm);
};

export const getInitialControlPanelDatasets = (): FormControlPanel => {
  return {
    [ControlPanelFields.sorting]: Sorting.newest,
    [ControlPanelFields.view]: ViewType.table,
  };
};

export const getInitialFiltersDatasets = (): FormFiltersDatasets => {
  return {
    [FiltersFields.compatible]: Compatible.all,
    [FiltersFields.offerType]: TOfferType.Data,
    [FiltersFields.offers]: Offers.sp,
  };
};

export const getInitialValuesDatasets = (
  props?: FormDatasets,
): FormDatasets => {
  const { filters, controlPanel } = props || {};
  return {
    [Blocks.filters]: { ...(filters ?? getInitialFiltersDatasets()) },
    [Blocks.controlPanel]: controlPanel || getInitialControlPanelDatasets(),
  };
};

export const getInitialControlPanelModels = (): FormControlPanel => {
  return {
    [ControlPanelFields.sorting]: Sorting.newest,
    [ControlPanelFields.view]: ViewType.table,
  };
};

export const getInitialFiltersModels = (): FormFiltersModels => {
  return {
    [FiltersFields.compatible]: Compatible.all,
    [FiltersFields.offerType]: TOfferType.Data,
    [FiltersFields.offers]: Offers.sp,
  };
};

export const getInitialValuesModels = (
  props?: FormModels,
): FormModels => {
  const { filters, controlPanel } = props || {};
  return {
    [Blocks.filters]: { ...(filters ?? getInitialFiltersModels()) },
    [Blocks.controlPanel]: controlPanel || getInitialControlPanelModels(),
  };
};

export const getInitialState = (): FiltersState => ({
  [Pages.models]: getInitialValuesModels(),
  [Pages.datasets]: getInitialValuesDatasets(),
});